<template>
  <FocusTrap>
    <div class="card">
      <div class="card-header bg-blue-800">
        <h6 class="card-title">Add Occasion</h6>
      </div>

      <div class="card-body">
        <form action="#">
          <div class="form-group ">
            <label>Date:</label>
            <input id="txtDate" type="date" class="form-control" placeholder="" v-model="holiday.date" :min="min_date" :max="max_date" >
          </div>

          <div class="form-group">
            <label>Occasion</label>
            <input type="text" class="form-control" placeholder="Occasion" maxlength="50" v-model="holiday.occasion" >
          </div>


          <div class="d-flex justify-content-between align-items-end">
            <button type="button" class="btn btn-outline-success" @click="perform_holiday_save" >Submit<i class="icon-database-add ml-2"></i></button>
          </div>
        </form>
      </div>
    </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
export default {
  name: 'HolidayWindow',
  store,
  data(){
    return {
      holiday : JSON.parse('{"id":0,"status":"WorkingDay","type":0,"date":"","occasion":""}')
    }
  },
  props: {
    min_date:{
      type: Date,
      default: () => moment(store.state.user.working_date).format('YYYY') + "-" + "01-01"
    },
    max_date:{
      type: Date,
      default: () => moment(store.state.user.working_date).format('YYYY') + "-" + "12-31"
    }
  },
  mounted () {
    $('txtDate').focus();
  },
  methods:{
    perform_holiday_save() {
      const self = this;
      try {
        // showInfo(self.$data.occasion.length);
        if (self.$data.holiday.date.length != 10 ) {
          alert("Invalid Date");
          return;
        } else if ( self.$data.holiday.occasion.length ==  0) {
          alert("Invalid Occasion");
          return;
        }
        self.$emit('holiday_save', self.$data.holiday);
      }catch (e) {
        alert(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
